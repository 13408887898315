export default [
  {
    path: '/admin',
    name: 'admin',
    component: () => import('@/views/admin/Admin.vue'),
    meta: {
      pageTitle: 'Admin',
      breadcrumb: [
        {
          text: 'Admin',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/calendar/classrooms',
    name: 'admin-classroom-calendar',
    component: () => import('@/views/admin/calendar/Calendar.vue'),
  },
  {
    path: '/admin/calendar/admin-calendar',
    name: 'admin-admin-calendar',
    component: () => import('@/views/admin/admin-calendar/Calendar.vue'),
  },
  {
    path: '/admin/calendar/teacher-calendar',
    name: 'admin-teacher-calendar',
    component: () => import('@/views/admin/teacher-calendar/Calendar.vue'),
  },
  {
    path: '/admin/calendar/day-off-calendar',
    name: 'admin-day-off-calendar',
    component: () => import('@/views/admin/day-off-calendar/Calendar.vue'),
  },
  {
    path: '/admin/classrooms/list',
    name: 'admin-classrooms-list',
    component: () => import('@/views/admin/classrooms-list/ClassroomsList.vue'),
  },
  // {
  //   path: '/admin/cities/list',
  //   name: 'admin-cities-list',
  //   component: () => import('@/views/admin/cities-list/CitiesList.vue'),
  // },
  // Teachers
  {
    path: '/admin/teachers/list',
    name: 'admin-teachers-list',
    component: () => import('@/views/admin/teachers-list/TeachersList.vue'),
  },
  {
    path: '/admin/events/list',
    name: 'admin-events-list',
    component: () => import('@/views/admin/events-list/EventsList.vue'),
  },
  {
    path: '/admin/statistics/statistics',
    name: 'admin-statistics',
    component: () => import('@/views/admin/statistics/statistics/Statistics.vue'),
  },
  {
    path: '/admin/statistics/teachers',
    name: 'admin-teacher-statistics',
    component: () => import('@/views/admin/statistics/teachers-statistics/Statistics.vue'),
  },
  {
    path: '/admin/statistics/courses&registrations',
    name: 'admin-courses-registrations',
    component: () => import('@/views/admin/statistics/courses-registrations/Statistics.vue'),
  },
  {
    path: '/admin/courses/reviews',
    name: 'admin-reviews',
    component: () => import('@/views/admin/reviews-list/ReviewsList.vue'),
  },
  {
    path: '/admin/emails',
    name: 'admin-emails',
    component: () => import('@/views/admin/emails-list/EmailsList.vue'),
  },
  {
    path: '/admin/resignations',
    name: 'admin-resignations-list',
    component: () => import('@/views/admin/resignations-list/ResignationsList.vue'),
  },
  {
    path: '/admin/courses/packages',
    name: 'admin-courses-packages',
    component: () => import('@/views/courses/admin/packages-list/PackagesList.vue'),
  },
  {
    path: '/admin/courses/approval',
    name: 'admin-course-approval',
    component: () => import('@/views/admin/courses-approval/CoursesList.vue'),
  },
  {
    path: '/notifications',
    name: 'notifications',
    component: () => import('@core/layouts/components/app-navbar/components/Notifications.vue'),
    meta: {
      pageTitle: 'Notifications',
      breadcrumb: [
        {
          text: 'Notifications',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/approval/history',
    name: 'admin-approval-history',
    component: () => import('@/views/admin/courses-approval/ApprovalListHistory.vue'),
  },
  {
    path: '/notifications/settings',
    name: 'notification-setting',
    component: () => import('@/views/pages/account-setting/AccountSettingNotificationSettings.vue'),
    meta: {
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/admin/activities',
    name: 'admin-activities',
    component: () => import('@/views/admin/log-list/LogList.vue'),
  },
  {
    path: '/admin/teachers/events',
    name: 'admin-teacher-events',
    component: () => import('@/views/admin/teachers-list/TeacherEvents.vue'),
  },
  {
    path: '/admin/claim/list',
    name: 'admin-claim-list',
    component: () => import('@/views/admin/claim-list/ClaimList.vue'),
  },
  {
    path: '/admin/group/list',
    name: 'admin-group-list',
    component: () => import('@/views/admin/groups-list/GroupsList.vue'),
  },
  {
    path: '/admin/school/settings',
    name: 'admin-school-settings',
    component: () => import('@/views/admin/settings/school-data/school-view/SchoolSettingsEdit.vue'),
  },
  {
    path: '/admin/school/settings/dashes',
    name: 'admin-school-settings-dashes',
    component: () => import('@/views/admin/settings/menu-shortcuts/views/SchoolDashesSettingsEdit.vue'),
  },
]
